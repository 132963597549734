@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* > * {
    font-family: "Poppins", cursive;
    margin: 0;
}

body{
  background: #7895CB;  
  }

.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.register{
  max-width: 978px;
  width: 100vw;
  display:flex;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  /* box-shadow: 1px 3px 10px #e9ecef; */
  background-color: white;
}

.register span{
  color: #adb5bd;
}

#form{
  max-width: 320px;
  width: 100vw;
  margin: 2em auto;
}

#form >  input, .btn{
  border: 1px solid #e9ecef;
  padding: .9em 1em;
}

#form > input:focus{
  outline: none;
}

#form > .btn{
  background-color: #003049;
  color: #e9ecef;
  font-size: 1em;
  cursor: pointer;
}

.flex{
  display: flex;
  gap: 1em;
}

.flex-col{
  flex-direction: column;
}

.register .col-1{
  margin: auto;
  padding: 3em 0;
}

.register .col-2 img{
  width: 480px;
  height: 100%;
  object-fit: cover;
  align-self: flex-end;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
}

@media screen and (min-width: 860px)  {
  .register .col-2 img{
    display: initial;
  }
}