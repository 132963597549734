
.expense-form-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  

  .expense-form-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  

  .expense-form-container button {
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .expense-form-container button:hover {
    background-color: #0056b3;
  }
  

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  
  .modal-content {
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  